














































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'Footer'
})

export default class Footer extends Vue {}
