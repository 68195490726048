





































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule, MessageState } from '@/store/modules/app'

@Component
export default class LocaleSelector extends Vue {
  public onCloseMessageBar (): void {
    AppModule.SetShowMessage(MessageState.CLOSED)
  }

  public mounted () {
    if (this.$t('message') === '') {
      AppModule.SetShowMessage(MessageState.EMPTY)
    }
  }

  public get locale () {
    return AppModule.locale
  }

  @Watch('locale')
  public onLocaleChange () {
    if (AppModule.showMessage === MessageState.VISIBLE && this.$t('message') === '') {
      AppModule.SetShowMessage(MessageState.EMPTY)
    } else if (AppModule.showMessage === MessageState.EMPTY && this.$t('message') !== '') {
      AppModule.SetShowMessage(MessageState.VISIBLE)
    }
  }

  public get showMessage () {
    return AppModule.showMessage === MessageState.VISIBLE
  }
}
