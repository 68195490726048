

























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { Facet, Filters, FilterType, SearchModule } from '@/store/modules/search'
import { TreeModel } from '@/util/treeModel'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

@Component({
  components: {
    Treeselect
  }
})

export default class HierarchieFilter extends Vue {
  private hierarchieValues: Array<string> = []

  get locale () {
    return AppModule.locale
  }

  get facets () {
    return SearchModule.facets
  }

  get filters () {
    return SearchModule.filters
  }

  mounted () {
    if (Object.prototype.hasOwnProperty.call(this.filters, 'hierarchie')) {
      this.hierarchieValues = this.filters.hierarchie.payload
    }
  }

  created () {
    window.addEventListener('popstate', this.handlePopState)
  }

  destroyed () {
    window.removeEventListener('popstate', this.handlePopState)
  }

  private handlePopState () {
    const filters = this.$route.query.filter
    let hierarchie = ''
    if (filters) {
      if (Array.isArray(filters)) {
        for (let i = 0; i < filters.length; i++) {
          const currentFilter = filters[i]
          if (currentFilter !== null) {
            if (currentFilter.substring(0, 1) === 'h') {
              hierarchie = currentFilter
            }
          }
        }
      } else {
        if (filters.substring(0, 1) === 'h') {
          hierarchie = filters
        }
      }
    }
    const hierarchies = hierarchie.substring(2)
    const hierarchieFilters = hierarchies.split(',')
    if (hierarchieFilters.includes('')) {
      hierarchieFilters.splice(hierarchieFilters.indexOf(''), 1)
    }
    const stateHierarchie = SearchModule.filters.hierarchie
    let stateHierarchieFilters: string[] = []
    let updateHierarchy = false
    if (stateHierarchie) {
      stateHierarchieFilters = stateHierarchie.payload
    }
    if (hierarchieFilters.length === stateHierarchieFilters.length) {
      for (let j = 0; j < hierarchieFilters.length; j++) {
        if (!stateHierarchieFilters.includes(hierarchieFilters[j])) {
          updateHierarchy = true
        }
      }
    } else {
      updateHierarchy = true
    }
    if (updateHierarchy) {
      if (hierarchieFilters.length !== 0) {
        this.hierarchieValues = hierarchieFilters
      } else {
        SearchModule.RemoveFilter(FilterType.HIERARCHY)
      }
    }
  }

  @Watch('hierarchieValues')
  public onHierarchieValuesChanged (values: Array<string>) {
    if (values.length > 0) {
      SearchModule.AddFilter({ type: FilterType.HIERARCHY, payload: values })
    } else {
      SearchModule.RemoveFilter(FilterType.HIERARCHY)
    }
  }

  @Watch('filters')
  public onFilterChanged (filters: Filters) {
    if (!Object.prototype.hasOwnProperty.call(filters, 'hierarchie')) {
      if (this.hierarchieValues.length > 0) {
        this.hierarchieValues = []
      }
    }
  }

  public transformFacets () {
    const tree: Array<TreeModel> = []
    const locale = this.locale
    const facets: Array<Facet> = this.facets
    const hierarchie = SearchModule.aggregations.hierarchie || []
    const hierarchieDict: { [key: string]: number } = {}
    for (const aggregation of hierarchie) {
      hierarchieDict[aggregation.key] = aggregation.count
    }
    const lookupCount = function (id: string) {
      if (hierarchieDict[id] !== undefined) {
        return hierarchieDict[id]
      }
      return 0
    }
    facets.forEach((facet: Facet) => {
      if (facet.children !== null && facet.children !== undefined) {
        if (facet.children.length > 0) {
          const childrenArray: Array<TreeModel> = []
          facet.children.forEach((child: Facet) => {
            if (child.children !== null && child.children !== undefined) {
              if (child.children.length > 0) {
                const grandChildrenArray: Array<TreeModel> = []
                child.children.forEach((grandChild: Facet) => {
                  grandChildrenArray.push({ id: grandChild.id, label: grandChild.label[locale], count: lookupCount(grandChild.id) })
                })
                childrenArray.push({ id: child.id, label: child.label[locale], children: grandChildrenArray, count: lookupCount(child.id) })
              }
            } else {
              childrenArray.push({ id: child.id, label: child.label[locale], count: lookupCount(child.id) })
            }
          })
          tree.push({ id: facet.id, label: facet.label[locale], children: childrenArray, count: lookupCount(facet.id) })
        }
      } else {
        tree.push({ id: facet.id, label: facet.label[locale], count: lookupCount(facet.id) })
      }
    })
    return tree
  }

  public getSearchable (): boolean {
    return window.innerWidth > 1024
  }
}
