



























































































































import { Component, Vue } from 'vue-property-decorator'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { AppModule, MessageState } from '@/store/modules/app'
import { store } from '@/store'

@Component({
  components: {
    Header,
    Footer
  }
})
export default class App extends Vue {
  public get showMessage () {
    return AppModule.showMessage === MessageState.VISIBLE
  }

  public get inSearch () {
    return store.state.route.path === '/search' || store.state.route.name === 'View'
  }
}
