















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'

@Component
export default class LocaleSelector extends Vue {
  private localeChangeSelected = 'de'

  public created () {
    this.localeChangeSelected = this.locale
  }

  public get locale () {
    return AppModule.locale
  }

  public get showLocaleSelector () {
    return AppModule.showLocaleSelector
  }

  @Watch('locale')
  public onLocaleChange (locale: string) {
    if (this.localeChangeSelected !== locale) {
      this.localeChangeSelected = locale
    }
  }

  @Watch('localeChangeSelected')
  public onShowLocaleSelectorChange (selectedLoale: string) {
    if (this.locale !== selectedLoale) {
      AppModule.SetLocale(selectedLoale)
      AppModule.SetShowLocaleSelector(false)
    }
  }

  public onCancelLocaleChange (): void {
    AppModule.SetShowLocaleSelector(false)
  }
}
