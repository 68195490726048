
















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import Search from '@/components/Search.vue'
import LocaleSelector from '@/components/LocaleSelector.vue'
import Message from '@/components/Message.vue'

@Component({
  components: {
    Search,
    LocaleSelector,
    Message
  }
})
export default class Header extends Vue {
  private showOptionalBar = true;

  public get locale () {
    return AppModule.locale
  }

  public onLocaleChange (): void {
    AppModule.SetShowLocaleSelector(!AppModule.showLocaleSelector)
  }

  public onCloseOptionalBar (): void {
    this.showOptionalBar = false
  }
}
