




































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { SearchModule } from '@/store/modules/search'
import router from '@/router'
import { Route } from 'vue-router'

@Component
export default class Search extends Vue {
  private searchterm = ''

  public get query () {
    return SearchModule.query
  }

  created () {
    this.searchterm = this.query
  }

  @Watch('$route')
  onRouteChange () {
    const name = this.$route.name
    if (name === 'Home' && this.searchterm !== '') {
      this.searchterm = ''
      SearchModule.SetQuery('')
    }
    if (name === 'View' && this.searchterm !== '') {
      this.searchterm = ''
    }
  }

  @Watch('query')
  public onQueryChange (query: string) {
    this.searchterm = query
  }

  public onSearch ($event: Event) {
    if ($event.target !== null) {
      ($event.target as HTMLElement).blur()
    }
    if (this.searchterm !== '') {
      SearchModule.SetQuery(this.searchterm)
      /*
      if (this.$route.name !== 'Search' ||
            (this.$route.query.query === undefined || this.$route.query.query !== this.searchterm)) {
        router.push({ name: 'Search', query: { query: this.searchterm } })
      }
       */
    }
  }
}
