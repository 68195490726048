
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'Home'
})

export default class Home extends Vue {
  mounted () {
    const bcards = document.getElementById('bcards')
    if (bcards) {
      const cards1 = bcards.children
      const zahl = cards1.length
      let belegt = 0
      const karten: {'card': Node; 'position': number}[] = []
      for (let i = 0; i < zahl; i++) {
        if (cards1[i].getAttribute('class') !== 'search-placeholder') {
          belegt++
          karten[i] = { card: cards1[i], position: Math.random() }
        }
      }
      karten.sort((a, b) => { return Math.sign(a.position - b.position) })

      for (let i = 0; i < belegt; i++) {
        bcards.appendChild((karten[i]).card)
      }
      for (let i = 0; i < belegt;) {
        const cards2 = bcards.children
        if (cards2[i].getAttribute('class') === 'search-placeholder') {
          bcards.appendChild(cards2[i])
        } else i++
      }
    }
  }

  public get locale () {
    return AppModule.locale
  }
}
